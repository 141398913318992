import React, { Component } from 'react';
import withRouter from 'react-router/withRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import loadable from '@loadable/component';

const NotFound = loadable(() =>
  import('../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);
const Slug = loadable(() => import('../../../components/slug/slug'));
const Card = loadable(() => import('../../../components/card/card'));
const MagazineArchive = loadable(() =>
  import('../../../components/magazine_archive/magazine_archive')
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

// eslint-disable-next-line react/prefer-stateless-function
class Magazine extends Component {
  render() {
    if (
      this.props.categoryPage.error ||
      this.props.categoryFeaturedFeed.error ||
      this.props.status === 404
    ) {
      return (
        <section className="category-page">
          <article className="category-page__main--error-pg">
            <NotFound />
          </article>
        </section>
      );
    }

    if (this.props.categoryPage.isLoading) {
      return (
        <section className="category-page">
          <article className="category-page__main">
            <LoadingSpinner />
          </article>
        </section>
      );
    }

    const {
      config,
      categoryFeaturedFeed,
      magazineLatestIssuePosts
    } = this.props;

    const pgName = get(categoryFeaturedFeed, 'category.name');
    const pgDesc = get(categoryFeaturedFeed, 'category.description');

    return (
      <section className="category-page category-page--magazine">
        <section className="page-title">
          <Slug slugName={pgName} className="category-page" />
          {pgDesc ? <h3 className="page-description">{pgDesc}</h3> : ''}
        </section>

        <div className="row row--magazine">
          <div className="row__aside">
            <Card
              type="magazine"
              metadata={get(magazineLatestIssuePosts, 'data.issue', {})}
              fullWidth={true}
              magPage={true}
              config={config}
            />
            <div className="small-cards">
              {get(magazineLatestIssuePosts, 'data.features', [])
                .slice(0, 3)
                .map(story => (
                  <Card
                    key={story.id}
                    type="small"
                    metadata={story}
                    disabledImage={true}
                    config={config}
                  />
                ))}
            </div>
          </div>
          <div className="row__main">
            <Card
              type="featured"
              metadata={get(magazineLatestIssuePosts, 'data.coverStory', {})}
              config={config}
            />
            <div className="card card--default card--subscribe">
              <a href="https://www.fastcompany.com/subscribe?cid=dan904:701:000:SCTA_&utm_source=paidDisplayOn&utm_medium=edit%20top%20nav&utm_campaign=dan904:701:000:SCTA_">
                <img
                  className="subscribe--rectangle"
                  src="https://images.fastcompany.net/image/upload/v1589388562/fcweb/Subscribe_Ad_814x197_ejtz9f.jpg"
                  alt="The future of business starts here. Subscribe now."
                />
                <img
                  className="subscribe--square"
                  src="https://images.fastcompany.net/image/upload/v1589388551/fcweb/Subscribe_Ad_360x313_qbfsha.jpg"
                  alt="The future of business starts here. Subscribe now."
                />
              </a>
            </div>
          </div>
        </div>

        <div className="category-page__ff-ad-container">
          <AdContainer type="break" />
        </div>

        <MagazineArchive
          config={config}
          title="Fast Company Magazine Archives"
          description="Browse our previous magazine issues"
          magazines={get(magazineLatestIssuePosts, 'data.magazineArchive', [])}
          limit={12}
        />
      </section>
    );
  }
}

function mapStateToProps(state = {}) {
  return {
    config: state.config || {},
    status: state.status.code,
    categoryFeaturedFeed: {
      source: get(state, 'categoryFeaturedFeed.source') || '',
      featured: get(state, 'categoryFeaturedFeed.featured') || [],
      top: get(state, 'categoryFeaturedFeed.top') || [],
      category: get(state, 'categoryFeaturedFeed.category') || {},
      error: get(state, 'categoryFeaturedFeed.error') || false
    },
    categoryPage: get(state, 'categoryPage') || {},
    categoryScrollingModule: get(state, 'categoryScrollingModule.data') || {},
    magazineLatestIssuePosts: get(state, 'magazineLatestIssuePosts') || {}
  };
}

Magazine.propTypes = {
  config: PropTypes.shape({}).isRequired,
  status: PropTypes.number.isRequired,
  categoryPage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired,
  categoryFeaturedFeed: PropTypes.shape({
    source: PropTypes.string,
    featured: PropTypes.arrayOf(PropTypes.shape({})),
    top: PropTypes.arrayOf(PropTypes.shape({})),
    category: PropTypes.object,
    error: PropTypes.bool
  }).isRequired,
  magazineLatestIssuePosts: PropTypes.shape({}).isRequired
  // categoryScrollingModule: PropTypes.shape({}).isRequired
};

export default withRouter(connect(mapStateToProps)(Magazine));
